import React from 'react'
import styled from 'styled-components'

import { Chat, ChatButtons, ChatMessage } from '@te-digi/styleguide'

import { ChatExample1 } from '../../examples/react/ChatExample1'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

const ChatWrapper = styled.div`
  & > button {
    bottom: 0;
    position: relative;
    right: 0;
  }
`
// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Chat"
    components={[
      { component: Chat },
      { component: ChatButtons },
      { component: ChatMessage }
    ]}
    status={[{ type: 'deprecated', version: '16.2.0' }]}
  >
    <Section>
      <Playground
        example={ChatExample1}
        WrapperComponent={ChatWrapper}
      />
    </Section>
  </Content>
)

export default Page
